import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

import { rhythm, scale } from "../utils/typography";

function Layout({ location, title, children }) {
    const {
        site: { siteMetadata },
    } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        social {
                            instagram
                        }
                    }
                }
            }
        `
    );

    const { instagram: instagramHandle } = siteMetadata.social;

    const rootPath = `${__PATH_PREFIX__}/`;
    let header;

    if (location.pathname === rootPath) {
        header = (
            <h1
                style={{
                    ...scale(1),
                    marginBottom: rhythm(1.5),
                    marginTop: 0,
                }}
            >
                <Link
                    style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        color: `inherit`,
                    }}
                    to={`/`}
                >
                    {title}
                </Link>
            </h1>
        );
    } else {
        header = (
            <h3
                style={{
                    fontFamily: `Montserrat, sans-serif`,
                    marginTop: 0,
                }}
            >
                <Link
                    style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        color: `inherit`,
                    }}
                    to={`/`}
                >
                    {title}
                </Link>
            </h3>
        );
    }
    return (
        <div
            style={{
                marginLeft: `auto`,
                marginRight: `auto`,
                maxWidth: rhythm(24),
                padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            }}
        >
            <header>{header}</header>
            <main>{children}</main>
            <footer style={{ marginTop: rhythm(3) }}>
                <a
                    href={`https://www.instagram.com/${instagramHandle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Instagram
                </a>
            </footer>
        </div>
    );
}

export default Layout;
